import React, { memo } from 'react';
import { M40PromotedContentModule } from '~/lib/data-contract';
import { Link, Image } from '~/shared/components';
import { ConditionalWrap, weakKey } from '~/shared/utils/jsx';
import { ModuleContainer } from '~/templates/blocks/components/ModuleContainer';
import { queries } from '~/theme/breakpoints';
import { Headline } from '../Headline/Headline';
import { StyledContent, StyledGrid, StyledImageContainer, StyledPromotedContent } from './styled';
import { itemMapper } from '../../utils/itemMapper';
import { TextBlockForContentCards } from '../TextBlockForContentCards';

export type M40PromotedContentProps = M40PromotedContentModule;

/**
 * A list of content page links with images, titles and a headline
 *
 */

export const M40PromotedContent = memo(({ items, headline, ...rest }: M40PromotedContentProps) => {
    const promotedItems = itemMapper(items);
    const columnCount = Math.min(promotedItems?.length || 0, 2);
    const imagesVW = 100 / columnCount;

    return (
        <ModuleContainer hasGutter {...rest}>
            <StyledContent>
                {headline ? <Headline children={headline} variant="display2" /> : null}
                <StyledGrid>
                    {promotedItems?.map((item) => {
                        const { image, callToAction } = item;
                        return (
                            <ConditionalWrap
                                if={Boolean(callToAction?.url)}
                                key={weakKey(item)}
                                wrap={(itemContent) => (
                                    <Link
                                        href={callToAction?.url || ''}
                                        title={callToAction?.title || ''}
                                        target={callToAction?.target}
                                        children={itemContent}
                                        type="router"
                                    />
                                )}
                            >
                                <StyledPromotedContent>
                                    {image?.src ? (
                                        <StyledImageContainer>
                                            <Image
                                                {...image}
                                                src={image.src}
                                                alt={image.name}
                                                layout="fill"
                                                objectFit="cover"
                                                cW={4}
                                                cH={3}
                                                sizes={`${queries.xs} 100vw, ${queries.md} ${imagesVW}vw`}
                                                hoverStyle="both"
                                                onLoadAnimation="swipe"
                                                skeletonShade="none"
                                            />
                                        </StyledImageContainer>
                                    ) : null}
                                    {callToAction?.url && (
                                        <TextBlockForContentCards headline={item.headline} />
                                    )}
                                </StyledPromotedContent>
                            </ConditionalWrap>
                        );
                    })}
                </StyledGrid>
            </StyledContent>
        </ModuleContainer>
    );
});
